import React from "react";
import styled from "styled-components";
import { OG, CDesc, TG } from "./Utility";
import CardContainer from "./CardContainer";

const TBox = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;
`;

const CardSentence = ({
  sentence,
  tags,
  originType,
  originName,
  originUrl,
}) => {
  return (
    <CardContainer>
      <TBox>
        <CDesc>{sentence}</CDesc> <TG tags={tags}></TG>
      </TBox>
      <OG url={originUrl}>
        {originType} | {originName}
      </OG>
    </CardContainer>
  );
};

export default CardSentence;
