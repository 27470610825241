import cover1 from "../Image/Book/cover1.png";
import cover2 from "../Image/Book/cover2.png";
import cover3 from "../Image/Book/cover3.png";
import cover4 from "../Image/Book/cover4.png";
import cover5 from "../Image/Book/cover5.png";
import cover6 from "../Image/Book/cover6.png";
import cover7 from "../Image/Book/cover7.png";
import cover8 from "../Image/Book/cover8.png";
import cover9 from "../Image/Book/cover9.png";
import cover10 from "../Image/Book/cover10.png";
import cover11 from "../Image/Book/cover11.png";
import cover12 from "../Image/Book/cover12.png";
import cover13 from "../Image/Book/cover13.png";
import cover14 from "../Image/Book/cover14.png";
import cover15 from "../Image/Book/cover15.png";
import cover16 from "../Image/Book/cover16.png";
import cover17 from "../Image/Book/cover17.png";
import cover18 from "../Image/Book/cover18.png";
import cover19 from "../Image/Book/cover19.png";
import cover20 from "../Image/Book/cover20.png";
import cover21 from "../Image/Book/cover21.png";
import cover22 from "../Image/Book/cover22.png";
import cover23 from "../Image/Book/cover23.png";
import cover24 from "../Image/Book/cover24.png";
import cover25 from "../Image/Book/cover25.png";
import cover26 from "../Image/Book/cover26.png";
import cover27 from "../Image/Book/cover27.png";
import cover28 from "../Image/Book/cover28.png";
import cover29 from "../Image/Book/cover29.png";
import cover30 from "../Image/Book/cover30.png";
import cover31 from "../Image/Book/cover31.png";
import cover32 from "../Image/Book/cover32.png";
import cover33 from "../Image/Book/cover33.png";
import cover34 from "../Image/Book/cover34.png";
import cover35 from "../Image/Book/cover35.png";
import cover36 from "../Image/Book/cover36.png";
import cover37 from "../Image/Book/cover37.png";
import cover38 from "../Image/Book/cover38.png";
import cover39 from "../Image/Book/cover39.png";
import cover40 from "../Image/Book/cover40.png";
import cover41 from "../Image/Book/cover41.png";
import cover42 from "../Image/Book/cover42.png";
import cover43 from "../Image/Book/cover43.png";
import cover44 from "../Image/Book/cover44.png";
import cover45 from "../Image/Book/cover45.png";
import cover46 from "../Image/Book/cover46.png";
import cover47 from "../Image/Book/cover47.png";
import cover48 from "../Image/Book/cover48.png";
import cover49 from "../Image/Book/cover49.png";
import cover50 from "../Image/Book/cover50.png";
import cover51 from "../Image/Book/cover51.png";
import cover52 from "../Image/Book/cover52.png";
import cover53 from "../Image/Book/cover53.png";
import cover54 from "../Image/Book/cover54.png";
import cover55 from "../Image/Book/cover55.png";
import cover56 from "../Image/Book/cover56.png";
import cover57 from "../Image/Book/cover57.png";
import cover58 from "../Image/Book/cover58.png";
import cover59 from "../Image/Book/cover59.png";
import cover60 from "../Image/Book/cover60.png";
import cover61 from "../Image/Book/cover61.png";
import cover62 from "../Image/Book/cover62.png";
import cover63 from "../Image/Book/cover63.png";
import cover64 from "../Image/Book/cover64.png";
import cover65 from "../Image/Book/cover65.png";
import cover66 from "../Image/Book/cover66.png";
import cover67 from "../Image/Book/cover67.png";
import cover68 from "../Image/Book/cover68.png";
import cover69 from "../Image/Book/cover69.png";
import cover70 from "../Image/Book/cover70.png";
import cover71 from "../Image/Book/cover71.png";
import cover72 from "../Image/Book/cover72.png";
import cover73 from "../Image/Book/cover73.png";
import cover74 from "../Image/Book/cover74.png";
import cover75 from "../Image/Book/cover75.png";
import cover76 from "../Image/Book/cover76.png";
import cover77 from "../Image/Book/cover77.png";
import cover78 from "../Image/Book/cover78.png";
import cover79 from "../Image/Book/cover79.png";
import cover80 from "../Image/Book/cover80.png";
import cover81 from "../Image/Book/cover81.png";
import cover82 from "../Image/Book/cover82.png";
import cover83 from "../Image/Book/cover83.png";
import cover84 from "../Image/Book/cover84.png";
import cover85 from "../Image/Book/cover85.png";
import cover86 from "../Image/Book/cover86.png";
import cover87 from "../Image/Book/cover87.png";
import cover88 from "../Image/Book/cover88.png";
import cover89 from "../Image/Book/cover89.png";
import cover90 from "../Image/Book/cover90.png";
import cover91 from "../Image/Book/cover91.png";

const BookArray = [
  {
    bookCover: cover91,
    title: "죽은 나무를 위한 애도",
    author: "헤르만 헤세",
    sentence:
      "나는 내 씨앗에 감춰진 비밀스러운 이야기를 끝까지 살아갈 뿐, 다른 것은 염려하지 않아요. 나는 신이 내 안에 있음을 믿어요. 그리고 내 삶의 과제가 성스럽다는 것을 믿지요. 나는 이런 믿음을 갖고 살아가요.",
    url: "https://product.kyobobook.co.kr/detail/S000000619379",
    tags: ["고독"],
  },
  {
    bookCover: cover90,
    title: "고독한 밤에 호루라기를 불어라",
    author: "이응준",
    sentence:
      "책 한 권이 모든 사람들을 진보시키진 못하더라도, 세상을 진보시킬 한 사람을 호명할 수는 있기 때문이다.",
    url: "https://product.kyobobook.co.kr/detail/S000208596987",
    tags: ["고독", "글쓰기"],
  },
  {
    bookCover: cover89,
    title: "좋은 기분",
    author: "박정수",
    sentence:
      "일과 삶의 차이점이 하나 있다면 일은 한 인간의 수명을 뛰어넘어 생각해야 한다는 점입니다. 좋은 일, 그리고 사람들에게 필요한 일이라면 계속되어야 하기 때문입니다.",
    url: "https://product.kyobobook.co.kr/detail/S000211692877",
    tags: ["출판전야"],
  },
  {
    bookCover: cover88,
    title: "소설가의 귓속말",
    author: "이승우",
    sentence: "평가할 수 없는, 판단 이전의 모든 것은 중요한 것이다.",
    url: "https://product.kyobobook.co.kr/detail/S000001941063",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover87,
    title: "나무",
    author: "고다 아야",
    sentence:
      "감탄사를 내뱉으며 감동했다는 말은 사실 그 시점에서 끈끈한 인연이 생겼다는 뜻인데 그렇게 생각하지 않고, 반대로 인연이 끊어졌다고 착각해 결국 몸을 사림으로써 근사한 것과의 인연을 거부한다.",
    url: "https://product.kyobobook.co.kr/detail/S000215008285",
    tags: ["출판전야"],
  },
  {
    bookCover: cover86,
    title: "일의 감각",
    author: "조수용",
    sentence:
      "오랫동안 공을 들였다고 해도 그게 드러나면 안 된다는 사실을 기억해야 합니다.",
    url: "https://product.kyobobook.co.kr/detail/S000213436378",
    tags: ["출판전야"],
  },
  {
    bookCover: cover85,
    title: "재미난 일을 하면 어떻게든 굴러간다",
    author: "미시마 쿠니히로",
    sentence:
      "그가 만들지 않으면 달리 만들 사람이 없을 법한 책을 만들기 위해서, 그가 발굴하지 않으면 묻히고 말, 다른 사람이 발견할 수 없는 재능을 캐내는 책을 만들기 위해서.",
    url: "https://product.kyobobook.co.kr/detail/S000213436378",
    tags: ["출판전야"],
  },
  {
    bookCover: cover84,
    title: "모국어는 차라리 침묵",
    author: "목정원",
    sentence:
      "그 일이 삼백 년쯤 뒤에 이루어지더라도 삼백 년 전의 사람으로서 할 일을 하고 싶던 꿈. 그런 꿈이 내게 있었다.",
    url: "https://product.kyobobook.co.kr/detail/S000001927133",
    tags: ["고독"],
  },
  {
    bookCover: cover83,
    title: "굴렌 굴드, 피아노 솔로",
    author: "미셸 슈나이더",
    sentence:
      "그들에게 '자신들의 내면으로 돌아오도록' 초대해 보시라. 그곳에서 그들은 아무도 자신을 기다리지 않는 집에 돌아와야 하는 사람의 혐오감을 느낄 것이다. 이 귀가가 풍성한 것이라고, 그들의 고독이 행복한 것이라고 축복해 주어도 소용 없다.",
    url: "https://product.kyobobook.co.kr/detail/S000213993072",
    tags: ["고독"],
  },
  {
    bookCover: cover82,
    title: "읽지 못하는 사람의 미래",
    author: "전병근",
    sentence:
      "모든 사람이 같은 행동을 하고 있었기 때문에 모두 괜찮을 것이라 지레짐작했다.",
    url: "https://product.kyobobook.co.kr/detail/S000214626114",
    tags: ["독서"],
  },
  {
    bookCover: cover81,
    title: "자발적 고독",
    author: "올리비에 르모",
    sentence:
      "오두막을 성역화해서는 안 된다. 오두막에 지나치게 큰 가치를 부여할 때마다 그곳은 낭만적인 오아시스나 아니면 세계 대종말에 대비한 동굴로 전락한다.",
    url: "https://product.kyobobook.co.kr/detail/S000001111195",
    tags: ["고독"],
  },
  {
    bookCover: cover80,
    title: "공간과 장소",
    author: "이-푸 투안",
    sentence:
      "다른 사람의 시선을 받게 되면서 그는 그 공간을 통제하는 유일한 주체에서 방 안의 수많은 객체들 중 하나가 됩니다. 그는 자신만의 고유한 관점에 따라 사물을 공간에 질서정연하게 배열하는 힘을 상실했다고 느낍니다.",
    url: "https://product.kyobobook.co.kr/detail/S000001519718",
    tags: ["고독", "출판전야"],
  },
  {
    bookCover: cover79,
    title: "단편 소설 쓰기의 모든 것",
    author: "데이먼 나이트",
    sentence:
      "학교를 졸업하자마자 현실 세계와의 접촉은 시도조차 하지 않고 전업 작가의 길로 들어서는 건 실수라고 생각한다.",
    url: "https://product.kyobobook.co.kr/detail/S000001738819",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover78,
    title: "고독에 관하여",
    author: "요한 G. 치머만",
    sentence:
      "고독이 아니라면 철학과 과학의 영역에 닿고자 하는 정신을 세상사의 짐과 속박에서 구해 낼 다른 방도는 없다.",
    url: "https://product.kyobobook.co.kr/detail/S000214204122",
    tags: ["고독"],
  },
  {
    bookCover: cover77,
    title: "여백 사고",
    author: "야마자키 세이타로",
    sentence:
      "모든 것을 통제하면서 내가 생각하는 최고의 상태를 만든 결과, 새로운 요소가 전혀 생겨나지 않게 되었습니다. 자신의 힘만으로 완결시키지 않고 타인과 우연의 힘을 곱할 수 있는 장소, 그곳이 바로 여백입니다.",
    url: "https://product.kyobobook.co.kr/detail/S000214127839",
    tags: ["출판전야"],
  },
  {
    bookCover: cover76,
    title: "깊이에의 강요",
    author: "파트리크 쥐스킨트",
    sentence:
      "문학의 건망증으로 고생하는 독자는 독서를 통해 변화하면서도, 독서하는 동안 자신이 변하고 있다는 것을 말해 줄 수 있는 두노의 비판 중추가 함께 변하기 때문에 그것을 깨닫지 못하는 것이다.",
    url: "https://product.kyobobook.co.kr/detail/S000000582058",
    tags: ["문학"],
  },
  {
    bookCover: cover75,
    title: "소설가라는 이상한 직업",
    author: "장강명",
    sentence:
      "한국 사회에도 구성원에게 영향을 미치는 자석과 자기력선은 존재한다. 많이 존재한다. 나는 그 자석들의 위치와 모양을 포착하고 싶다. 그러다 보니 철 가루보다는 자기력선에 좀 더 관심이 간다.",
    url: "https://product.kyobobook.co.kr/detail/S000200832181",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover74,
    title: "고마워 책방",
    author: "손정승, 음소정",
    sentence:
      "난 왜 이 일을 하고 싶었을까, 왜 여기서 일하고 싶었을까. '언저리'라는 말을 다시 소환해 본다. 내가 좋아하는 건 책 자체도 있지만 어쩌면 이 책을 쓰고 만들고 읽는, 이를 둘러싼 사람들까지가 아닐까?",
    url: "https://product.kyobobook.co.kr/detail/S000001851333",
    tags: ["출판전야"],
  },
  {
    bookCover: cover73,
    title: "쓰는 기분",
    author: "박연준",
    sentence:
      "내가 이 일을 하는지에 대한 거창한 목표가 필요하다. 그래야 돈이 없어도, 평가가 없어도 견딜 수 있다.",
    url: "https://product.kyobobook.co.kr/detail/S000000575067",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover72,
    title: "인디펜던트 워커",
    author: "정혜윤 외 10명",
    sentence:
      "내가 이 일을 하는지에 대한 거창한 목표가 필요하다. 그래야 돈이 없어도, 평가가 없어도 견딜 수 있다.",
    url: "https://product.kyobobook.co.kr/detail/S000001944974",
    tags: ["출판전야"],
  },
  {
    bookCover: cover71,
    title: "짧게 잘 쓰는 법",
    author: "벌린 클링켄보그",
    sentence:
      "우리의 글에 온갖 논리를 가져다 채워넣고 독자가 스스로 좋은 사색을 한 것처럼 착각하게 만듭니다. 평범한 독자라는 관념은 공허한 자만의 결과입니다. 평범함은 평범함을 낳습니다. 독자의 한계를 예단하지 마세요. 여러분이 그 한계에 갇히게 됩니다.",
    url: "https://product.kyobobook.co.kr/detail/S000001938262",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover70,
    title: "아무튼, SF게임",
    author: "김초엽",
    sentence:
      "대중 취향에 맞추어 갈 수밖에 없는 매체들과 달리 소설은 소설가 한 명이 먹고살 수 있으면 어떻게든 작품이 나오게 되어 있다. 그렇기에 소설은 눈치를 덜 본다.",
    url: "https://product.kyobobook.co.kr/detail/S000213676518",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover69,
    title: "동사의 맛",
    author: "김정선",
    sentence:
      "자신의 삶을 스스로 규정하는 데 쓰라고 내 삶을 빌려주는 셈이랄까. 그건 마치 낱말의 풀이가 또 다른 낱말들로 이루어지는 것과 다르지 않다. 사전을 보면 모든 낱말이 분명한 제 뜻을 갖고 있는 것 같지만 사실은 모두 다른 낱말에 기대고 있을 뿐 그 자체로는 이도 저도 아니다.",
    url: "https://product.kyobobook.co.kr/detail/S000001863128",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover68,
    title: "글쓰기의 최전선",
    author: "은유",
    sentence:
      "글감의 고갈에 직면하는 이유는 삶 혹은 나에 대한 인식의 한계에서 비롯한다. 어쩌면 글감의 빈곤은 존재의 빈곤이고, 존재의 빈곤은 존재의 외면일지도 모른다. 글감의 광맥은 '나'에게 있고, '나'란 관계의 앙상블이다.",
    url: "https://product.kyobobook.co.kr/detail/S000200116111",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover67,
    title: "건축가의 공간 일",
    author: "조성익",
    sentence: "'내가 가치를 두는 일이 인테리어를 통해 일어나는가'의 문제이다.",
    url: "https://product.kyobobook.co.kr/detail/S000213326771",
    tags: ["출판전야"],
  },
  {
    bookCover: cover66,
    title: "나로 존재하는 법",
    author: "헤르만 헤세",
    sentence:
      "여러분 자신이 되세요. 그러면 세상은 풍성하고 아름다워집니다! 여러분 자신이 되지 않고 거짓말쟁이와 겁쟁이가 되면, 세상은 가련해지고 개선이 필요한 곳으로 보이게 되는 것입니다.",
    url: "https://product.kyobobook.co.kr/detail/S000212169903",
    tags: ["고독"],
  },
  {
    bookCover: cover65,
    title: "아무것도 하지 않는 법",
    author: "제니 오델",
    sentence:
      "우리를 억압하는 세력은 자기표현을 막지 않습니다. 오히려 자신을 표현하라고 강요합니다. 할 말이 없다는 것, 아무것도 말하지 않을 권리가 있다는 것은 얼마나 다행입니까. 오로지 그때에만 말할 가치가 있는 극히 드문 것들을 만들어낼 기회가 있습니다.",
    url: "https://product.kyobobook.co.kr/detail/S000202707652",
    tags: ["고독"],
  },
  {
    bookCover: cover64,
    title: "오모테나시, 접객의 비밀",
    author: "최한우",
    sentence:
      "투숙한 고객이 손님이 아니라, 투숙하려고 생각하고 있는 고객부터 우리의 손님이다.",
    url: "https://product.kyobobook.co.kr/detail/S000001895637",
    tags: ["출판전야"],
  },
  {
    bookCover: cover63,
    title: "순도 100퍼센트의 휴식",
    author: "박상영",
    sentence:
      "'어쩌면 내게 있어 여행은 '휴식'의 동의어나 유의어가 아니라, 일상의 시름을 잊게 해주는 또 다른 자극이나 더 큰 고통에 가까운 행위가 아닐까? 환부를 꿰뚫어 통증을 잊게 하는 침구술처럼 일상 한중간을 꿰뚫어, 지리멸렬한 일상도 실은 살 만한 것이라는 걸 체감하게 하는 과정일 수도.",
    url: "https://product.kyobobook.co.kr/detail/S000202740435",
    tags: ["환기"],
  },
  {
    bookCover: cover62,
    title: "여행의 이유",
    author: "김영하",
    sentence:
      "고통은 수시로 사람들이 사는 장소와 연관되고, 그래서 그들은 여행의 필요성을 느끼는데, 그것은 행복을 찾기 위해서가 아니라 자신들의 슬픔을 몽땅 흡수한 것처럼 보이는 물건들로부터 달아나기 위해서다.",
    url: "https://product.kyobobook.co.kr/detail/S000212972861",
    tags: ["환기"],
  },
  {
    bookCover: cover61,
    title: "진정한 장소",
    author: "아니 에르노",
    sentence:
      "글쓰기가 무엇인지를 말하는 것은 조금 더 잘 할 수 있어요. 만약 누군가가 저를 최후의 참호로 몬다면, 그래도 스스로 존재하고 있다는 것을 가장 잘 느끼는 곳은 역시 거기이니까. 저만의 진정한 장소이죠.",
    url: "https://product.kyobobook.co.kr/detail/S000060627914",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover60,
    title: "끌리는 이야기는 어떻게 쓰는가",
    author: "리사 크론",
    sentence:
      "'잘 쓰는 법'을 배우는 것은 '이야기 쓰는 법'을 배우는 것과 동의어가 아니다. 잘 쓰는 것은 두 번째 문제다.",
    url: "https://product.kyobobook.co.kr/detail/S000212170062",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover59,
    title: "나는 메트로폴리탄 미술관의 경비원입니다.",
    author: "패트릭 브링리",
    sentence:
      "앞으로 나아가기만 하는 세상에서 빠져나가 온종일 아름답기만 한 세상에서 시간을 보낸다는 속임수가 과연 가능한 것일까?",
    url: "https://product.kyobobook.co.kr/detail/S000211352514",
    tags: ["출판전야"],
  },
  {
    bookCover: cover58,
    title: "GARM Magazine: 조명",
    author: "garmSSI",
    sentence:
      "독서를 하거나 공부하는 공간은 집중할 수 있도록 국부조명을 권한다. 은은한 간접조명보다는 작업면을 환하게 밝힐 수 있는 직접조명이 좋다. 테이블 스탠드만 사용하면 주변에 시선을 끄는 요소를 어둠으로 차단하니 집중력이 낮은 아이들이 온전히 책에 집중할 수 있다.",
    url: "https://product.kyobobook.co.kr/detail/S000001927275",
    tags: ["출판전야"],
  },
  {
    bookCover: cover57,
    title: "제주의 3년 이하 이주민의 가게들: 원했던 삶의 방식을 일궜는가?",
    author: "브로드컬리",
    sentence:
      "제공하는 가치보다 돈을 더 받기 무섭다. 돈이 그냥 숫자로 보이지 않는다. 손님의 삶이라 생각한다.",
    url: "https://www.yes24.com/Product/Goods/59310081",
    tags: ["출판전야"],
  },
  {
    bookCover: cover56,
    title: "서울의 3년 이하 서점들: 솔직히 책이 정말 팔릴 거라 생각했나?",
    author: "브로드컬리",
    sentence:
      "집은 아무리 공들여 꾸며 놔도 결국은 생활감이 묻어나지 않나. 설거지가 쌓이고 빨래가 쌓이고. 하지만 서점은 좋아하는 것들로만 편집해 둔 장소이기 때문에, 나에게는 그야말로 이상적인 공간이다.",
    url: "https://www.yes24.com/Product/Goods/59310082",
    tags: ["출판전야"],
  },
  {
    bookCover: cover55,
    title: "서울의 3년 이하 서점들: 책 팔아서 먹고살 수 있느냐고 묻는다면?",
    author: "브로드컬리",
    sentence:
      "오히려 얼마 동안 얼마간의 적자를 감수할 수 있을지의 계산이 필요하다.",
    url: "https://www.yes24.com/Product/Goods/59310084",
    tags: ["출판전야"],
  },
  {
    bookCover: cover54,
    title: "번역 : 황석희",
    author: "황석희",
    sentence:
      "자막의 어휘 수준은 캐릭터를 기준으로 삼는 것이 온당하다. 캐릭터는 현학적인 어투를 쓰는데 무조건 술술 읽히도록 쉬운 어투로 윤색해버리면 캐릭터도 사라지고 연출자와 작가의 의도도 사라진다.",
    url: "https://www.yes24.com/Product/Goods/123758039",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover53,
    title: "멀고도 가까운",
    author: "리베카 솔닛",
    sentence: "정말 좋은 이유가 없다면 절대로 모험을 거절하지 말자.",
    url: "https://product.kyobobook.co.kr/detail/S000001291143",
    tags: ["고독"],
  },
  {
    bookCover: cover52,
    title: "소설의 첫 문장: 다 사는 삶을 위하여",
    author: "김정선",
    sentence:
      "처음부터 이렇게 살려던 것도 아니었고, 처음부터 이렇게 쓰려던 것도 아니었노라고 스스로에게 말해 볼 수 있다면.",
    url: "https://product.kyobobook.co.kr/detail/S000001863153",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover51,
    title: "어휘 늘리는 법",
    author: "박일환",
    sentence:
      "절의 댓돌 위에 써 뿥인 '조고각하'는 자신이 벗은 신발을 잘 살피고 가지런히 놓으라는 뜻을 담고 있다. 불전에 들면서 자기 신발조차 똑바로 놓지 못한다면 그런 마음가짐으로 무슨 수행을 하겠냐는 뜻을 전하는 말이기도 하다.",
    url: "https://product.kyobobook.co.kr/detail/S000001863175",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover50,
    title: "지적자본론",
    author: "마스다 무네아키",
    sentence:
      "내가 사장이고 그들이 사원이라고 해서, 나는 자본가이고 그들은 노동자라고 생각해서는 안 된다. 그들이야말로 확실한 '지적자본'을 보유하고 있는 자본가이기 때문이다.",
    url: "https://product.kyobobook.co.kr/detail/S000000619479",
    tags: ["출판전야"],
  },
  {
    bookCover: cover49,
    title: "그 작가, 그 공간",
    author: "최재봉",
    sentence:
      "인생이란 이야기에 있는 게 아니라 그 이야기 사이의 공백에 있는 게 아닐까 하는 생각마저 들어. 그런데 편집은 목소리 사이의 공백을 없애는 일이잖아. 목소리와 목소리 사이에서 기침이나 한숨 소리, 침 삼키는 소리 같은 걸 찾아내서 없애는 거야. 그러면 이상하게 되게 외로워져.",
    url: "https://product.kyobobook.co.kr/detail/S000001307029",
    tags: ["출판전야"],
  },
  {
    bookCover: cover48,
    title: "집을 쫓는 모험",
    author: "정성갑",
    sentence:
      "'미래를 위해'라는 전제 자체가 틀린 것이었다. 오늘이 망가지면 도미노처럼 내일도 망가진다는 걸 몰랐다. 오늘을 망치는 것, 망치는 오늘이 쌓이는 것, 그건 미래를 잃는 것이기도 했다.",
    url: "https://product.kyobobook.co.kr/detail/S000001945823",
    tags: ["출판전야"],
  },
  {
    bookCover: cover47,
    title: "문학하는 마음",
    author: "김필균",
    sentence: "돈 안 되는 거 아는데 외롭지나 말자.",
    url: "https://product.kyobobook.co.kr/detail/S000001913368",
    tags: ["출판전야"],
  },
  {
    bookCover: cover46,
    title: "돈이 아닌 것들을 버는 가게",
    author: "남형석",
    sentence:
      "나의 서재를 만들고 이야기를 불러 모으는 공간으로 모두에게 열어두면 될 일",
    url: "https://product.kyobobook.co.kr/detail/S000061757995",
    tags: ["출판전야"],
  },

  {
    bookCover: cover45,
    title: "내 마음을 담은 집",
    author: "서현",
    sentence:
      "집은 감탄의 아우성보다는 내밀한 시어로 채워져야 하는 공간이다. 집에 들어와서 만나야 하는 건 나의 마음이지 그들의 탄성은 아닐 것이다.",
    url: "https://product.kyobobook.co.kr/detail/S000000880916",
    tags: ["출판전야"],
  },
  {
    bookCover: cover44,
    title: "소설은 실패를 먹고 자란다",
    author: "정진영",
    sentence:
      "다양한 경험, 그중에서도 실패한 경험이 많을수록 소설을 쓰는 데 좋다. 소설은 얄궃게도 실패를 먹고 자란다.",
    url: "https://product.kyobobook.co.kr/detail/S000211314286",
    tags: ["출판전야", "글쓰기"],
  },
  {
    bookCover: cover43,
    title: "밤에 일하고 낮에 쉽니다",
    author: "정인성",
    sentence: "좋아하는 것들을 꾸준하게 지켜낸 끝에 내 일을 찾았습니다.",
    url: "https://product.kyobobook.co.kr/detail/S000001899603",
    tags: ["출판전야"],
  },
  {
    bookCover: cover42,
    title: "에디터도 많이 틀리는 맞춤법",
    author: "임병천",
    sentence:
      "거듭 반복하여 이야기하는 사전은 국립국어원 표준국어대사전(stdict.korean.go.kr)을 이야기하는 것으로, 네이버와 다음 등의 포털 사이트에서도 따로 사전 기능을 제공하나, 이것 또한 표준국어대사전의 데이터를 활용한 것이므로 표준국어대사전이 가장 빠르고 정확한 표준어를 검색할 수 있습니다.",
    url: "https://product.kyobobook.co.kr/detail/S000001970059",
    tags: ["퇴고"],
  },
  {
    bookCover: cover41,
    title: "Music For Inner Peace",
    author: "박정용",
    sentence:
      "고독을 테마로 한 16곡의 재즈 발라드가 수록되었는데, 'Blue Moon', 'Love For Sale' 등 모든 곡에서 잊을 수 없는 목소리와 연주를 들려준다.",
    url: "https://product.kyobobook.co.kr/detail/S000001985388",
    tags: ["음악"],
  },
  {
    bookCover: cover40,
    title: "명랑한 은둔자",
    author: "캐럴라인 냅",
    sentence: "나는 명랑한 은둔자야.",
    url: "https://product.kyobobook.co.kr/detail/S000001078137",
    tags: ["고독"],
  },
  {
    bookCover: cover39,
    title: "코르뷔지에 넌 오늘도 행복하니",
    author: "에이리 가족, 네임리스 건축",
    sentence:
      "우리가 선호하는 남향은 주거 공간에서는 괜찮지만, 많은 양의 빛 때문에 학습 공간에서는 오히려 부담스럽다. 또한 동향은 아침에 깊숙하게 해가 들어오기 때문에 주로 밤 늦게까지 작업하고 늦게 일어나는 사람이라면 삶의 패턴이 깨질 수도 있다.",
    url: "https://product.kyobobook.co.kr/detail/S000001078137",
    tags: ["출판전야"],
  },
  {
    bookCover: cover38,
    title: "퇴고의 힘",
    author: "맷 벨",
    sentence:
      "저자의 즐거움은 저자를 이끄는 최고의 안내자다. 당신의 책이 독자에게 어떤 즐거움을 줄지는 아직 모르지만, 당신에게 즐거움을 주는지부터 우선 생각해보라.",
    url: "https://product.kyobobook.co.kr/detail/S000202374908",
    tags: ["퇴고"],
  },
  {
    bookCover: cover37,
    title: "김호연의 작업실",
    author: "김호연",
    sentence:
      "작가에게 작업실이란 글쓰기를 방해하는 모든 요소를 제거한 진공의 공간이며, 그 자체로 글쓰기 세계로 진입하는 웜홀이다. 그러므로 진지하게 소설을 쓰고자 하는 사람은 자신만의 작업실 또는 작업공간을 구해야 한다.",
    url: "https://product.kyobobook.co.kr/detail/S000201033098",
    tags: ["출판전야"],
  },
  {
    bookCover: cover36,
    title: "좋아서, 혼자서",
    author: "윤동희",
    sentence:
      "최소한의 사람으로 이루어질 때 드라마틱한 인생이 만들어진다. 한 사람을 클로즈업할 때 드라마가 된다.",
    url: "https://product.kyobobook.co.kr/detail/S000001764095",
    tags: ["고독"],
  },
  {
    bookCover: cover35,
    title: "JOBS 잡스 - NOVELIST 소설가",
    author: "매거진 B",
    sentence:
      "음악이나 연기와는 다르게 소설은 어디까지나 혼자서 임하는 작업이라는 점이 매우 마음에 들어요.",
    url: "https://www.yes24.com/Product/Goods/91107494",
    tags: ["출판전야"],
  },
  {
    bookCover: cover34,
    title: "작가의 루틴: 소설 쓰는 하루",
    author: "김중혁 외 6명",
    sentence:
      "타인의 글을 읽으며 감탄하고, 배우고, 부러워하다 보면 나의 글을 쓰고 싶다는 욕구에도 서서히 불이 들어온다.",
    url: "https://www.yes24.com/Product/Goods/116868766",
    tags: ["출판전야"],
  },
  {
    bookCover: cover33,
    title: "디앤디파트먼트에서 배운다",
    author: "나가오케 겐메이",
    sentence:
      "물건을 파는 사람은 싸게 판매함으로써 잃어버리는 의미에 대해 진지하게 고민해야 합니다.",
    url: "https://www.yes24.com/Product/Goods/15350907",
    tags: ["출판전야"],
  },
  {
    bookCover: cover32,
    title: "마감하면서 듣는 음악",
    author: "전은경",
    sentence:
      "이 곡이 끝나기 전에 편집자의 글을 다 쓴다, 이런 식으로 몰아붙이는 것이다.",
    url: "https://www.yes24.com/Product/Goods/118167359",
    tags: ["음악"],
  },
  {
    bookCover: cover31,
    title: "AROUND 작업실에서",
    author: "어라운드",
    sentence:
      "글을 쓰다 보면 아주 가끔씩 어떤 존에 진입했다는 기분이 들 때가 있다. 이 존 안에서는 플레이되고 있는 음악도 자연스럽게 삭제된다. 들리는 거라고는 오직 타닥타닥을 반복하는 기계식 키보드 소리뿐. 이 순간을 나는 정말이지 사랑한다.",
    url: "https://www.yes24.com/Product/Goods/124839340",
    tags: ["출판전야", "음악"],
  },
  {
    bookCover: cover30,
    title: "문장수집가 LOVE MY SELF",
    author: "아틀리에 드 에디토",
    sentence:
      "당신이 더 나이 들었을 때 자신과의 만남을 즐길 수 있도록 자신을 기록하라.",
    url: "https://www.yes24.com/Product/Goods/89105066",
    tags: ["고독"],
  },
  {
    bookCover: cover29,
    title: "작가의 책상",
    author: "질 크레멘츠",
    sentence:
      "글을 쓸 때의 기분이나 글을 쓰고자 하는 감성적 욕구가 작문 시간에 배우는 인지적인 기술보다 본질적으로 더 중요하다.",
    url: "https://www.yes24.com/Product/Goods/58816003",
    tags: ["출판전야"],
  },
  {
    bookCover: cover28,
    title: "자기만의 방으로",
    author: "고운 외 10명",
    sentence:
      "책상은 나의 모든 글들이 태어나는 심장이자 온 우주의 중심이므로 일단 책상의 위치가 정해져야 다른 가구들이 자리를 잡을 터였다.",
    url: "https://www.yes24.com/Product/Goods/124755855",
    tags: ["출판전야"],
  },
  {
    bookCover: cover27,
    title: "글쓰는 여자의 공간",
    author: "타니아 슐리",
    sentence: "나 자신을 글쓰기로 몰아넣기 위해 내 방이 있어야 합니다.",
    url: "https://www.yes24.com/Product/Goods/92804387",
    tags: ["출판전야"],
  },
  {
    bookCover: cover26,
    title: "혼자 있는 시간의 힘",
    author: "사이토 다카시",
    sentence:
      "나는 '침잠'이라는 말을 좋아한다. 물속 깊숙이 잠기면 무음의 세계를 떠도는 듯한 고요함에 휘감긴다. 그런 고요함 속에서 혼자 무언가에 몰두했다고 하자. 그렇게 만들어진 것은 영원히 사라지지 않는다. 물 위에 떠오른 뒤에도 자기 안에 존재한다.",
    url: "https://www.yes24.com/Product/Goods/19419272",
    tags: ["고독"],
  },
  {
    bookCover: cover25,
    title: "창조적 행위: 존재의 방식",
    author: "릭 루빈",
    sentence:
      "번개에 집착하지 않고 번개를 둘러싼 공간에 집중하는 것이 더 건설적인 전략이다. 올바른 사전 조건이 충족되지 않으면 번개가 치지 않고, 그 전기를 포착해 사용하지 않으면 소멸해버리기 때문에 공간이 중요하다.",
    url: "https://www.yes24.com/Product/Goods/119886818",
    tags: ["영감"],
  },
  {
    bookCover: cover24,
    title: "에디토리얼 씽킹",
    author: "최혜진",
    sentence:
      "글쓰기, 편집, 창작은 오류를 없애는 작업이 아니다. 다르게 해석할 여지가 있음에도 한쪽 손을 들어주는 일, 입장을 밝히는 일, 오류를 품고 프레임을 치는 일이다.",
    url: "https://www.yes24.com/Product/Goods/124006476",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover23,
    title: "걷기의 즐거움",
    author: "존 다이어 외 36명",
    sentence: "글쓰기란 결국 이리저리 산책을 하다가 우연히 얻은 부산물이다.",
    url: "https://www.yes24.com/Product/Goods/122895044",
    tags: ["영감"],
  },
  {
    bookCover: cover22,
    title: "영감의 말들",
    author: "김목인",
    sentence:
      "비범함은 야외를 좋아한다. 집중하는 정신을 좋아한다. 고독을 좋아한다.",
    url: "https://www.yes24.com/Product/Goods/115850603",
    tags: ["영감"],
  },
  {
    bookCover: cover21,
    title: "디자이너 생각 위를 걷다",
    author: "나가오카 겐메이",
    sentence:
      "사람은 다른 사람으로부터 의뢰가 들어오지 않은 상태에서도 창작을 할 수 있어야 비로소 창조가가 될 수 있다. 정말 어렵다. 아무도 의뢰하지 않은, 돈도 받을 수 없는 자기와의 싸움이기 때문이다. 아무리 작은 일이라도 상관없다. 의뢰받지 않은 일을 시작해 보자.",
    url: "https://www.yes24.com/Product/Goods/3565765",
    tags: ["창작"],
  },
  {
    bookCover: cover20,
    title: "쓰기의 말들",
    author: "은유",
    sentence:
      "'나'라는 피할 수도 물릴 수도 없는 출발점, '나'만 살아왔고 살아가는 엄정한 조건항으로 나를 원위치시킨다.",
    url: "https://www.yes24.com/Product/Goods/30177447",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover19,
    title: "어떤 고독은 외롭지 않다",
    author: "헨리 데이비드 소로 외 13명",
    sentence:
      "우리는 반드시 혼자 있을 수 있어야 하며, 거기서 진정으로 자유로워질 수 있는 나만의 방을 마련해야 한다. 고독을 올바르게 활용하는 법을 아는 사람들에게 그런 칩거란 세계 속에 또 하나의 세계가 있는 것과 같다.",
    url: "https://www.yes24.com/Product/Goods/115569952",
    tags: ["고독"],
  },
  {
    bookCover: cover18,
    title: "친애하는 나의 집에게",
    author: "하재영",
    sentence:
      "읽는 데에서 나아가 쓰는 사람이 되고자 한다면 자기만의 방이 가지는 의미는 더 각별해진다. 메리 올리버는 말했다. 창작은 고독을 요한다. 덧붙이자면 고독은 장소를 요한다. 휴대전화를 꺼놓을 수 있고, 창문을 닫아둘 수 있으며, 나를 부르는 타인의 목소리를 듣지 않을 수 있는 장소. 실생활과 최대한 먼 장소. 영감의 순간에 이를 때까지 침잠하고 몰입할 수 있는 장소.",
    url: "https://www.yes24.com/Product/Goods/95713416",
    tags: ["고독"],
  },
  {
    bookCover: cover17,
    title: "싯다르타",
    author: "헤르만 헤세",
    sentence:
      "자기 자신의 내면에까지 파고 들어가 본 사람만이 그렇게 진심으로 바라보고 그렇게 걸어갈 수 있을 것이다. 좋다. 나도 내 자신의 궁극의 심부에까지 파고 들어가기 위해 탐색하리라.",
    url: "yes24.com/Product/Goods/118599597",
    tags: ["고독"],
  },
  {
    bookCover: cover16,
    title: "달리기를 말할 때 내가 하고 싶은 이야기",
    author: "무라카미 하루키",
    sentence:
      "창작자에게 있어 그 동기는 자신 안에 조용히 확실하게 존재하는 것으로서, 외부에서 어떤 형태나 기준을 찾아야 할 일은 아니다.",
    url: "https://www.yes24.com/Product/Goods/3239082",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover15,
    title: "당신의 글은 어떻게 시작되었나요",
    author: "강준서 외 6명",
    sentence:
      "여백 앞에서 버티는 이 시간이 모이면 모일수록 생각은 명료해지고 글은 점점 더 좋아질 거야.",
    url: "https://www.yes24.com/Product/Goods/68719229",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover14,
    title: "권외편집자",
    author: "츠즈키 쿄이치",
    sentence:
      "정말 엄청난 책을 만드는 사람은 평범하고 과묵하며 혼자서 꾸준히 하는 작업을 좋아하는 이들뿐이었다. 말로 설명하지 않고 무언가를 만들어서 모두에게 보여주는 행위가 그들에게는 일종의 커뮤니케이션인 것이다.",
    url: "https://www.yes24.com/Product/Goods/36953980",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover13,
    title: "무소유",
    author: "법정",
    sentence:
      "그럼 인간의 말은 어디에서 나와야 할까. 그것은 마땅히 침묵에서 나와야 한다. 침묵을 배경으로 하지 않는 말은 소음과 다를 게 없다. 인간은 침묵 속에서만이 사물을 깊이 통찰할 수 있고 또한 자기 존재를 자각한다. 이때 비로소 자기 언어를 갖게 되고 자기 말에 책임을 느낀다. 그러기 때문에 투명한 사람끼리는 말이 없어도 즐겁다. 소리를 입 밖에 내지 않을 뿐 무수한 말이 침묵 속에서 오고 간다.",
    url: "https://product.kyobobook.co.kr/detail/S000000421213",
    tags: ["고독"],
  },
  {
    bookCover: cover12,
    title: "준최선의 롱런",
    author: "문보영",
    sentence:
      "매 순간 최선을 다해 번아웃되지 않고 최선 직전에서 어슬렁거리며 간 보기. 준최선으로 비벼 보기. 멀리 봤을 때, 최선보다 준최선이 가성비가 더 좋을지 모른다. 최선은 관성을 깨는 행위이기 때문에 관성이나 습관이 될 수 없지만, 준최선은 관성이 될 수 있기 때문이다. 대충하는 것은 아닌데 최선을 다하는 것도 아니고 그 사이에서 묵묵하게 롱런하기.",
    url: "https://www.yes24.com/Product/Goods/83526545",
    tags: ["글쓰기"],
  },
  {
    bookCover: cover11,
    title: "젊은 시인에게 보내는 편지",
    author: "라이너 마리아 릴케",
    sentence:
      "당신의 고독을 사랑하십시오. 그리고 그것이 아름다운 비탄의 소리를 내며 당신에게 주는 고통을 견디십시오. 왜냐하면 당신과 가까운 사람들이 멀게 여겨진다고 당신은 말합니다만, 그것은 당신의 주변이 넓어지기 시작한 표시이기 때문입니다. 그리고 만약 당신의 가까운 것이 멀리에 있다면, 당신의 영역은 이미 별들 사이에까지 퍼져서 실로 커다란 것입니다.",
    url: "https://www.yes24.com/Product/Goods/60648038",
    tags: ["고독"],
  },
  {
    bookCover: cover10,
    title: "고독의 창조적 기쁨",
    author: "펜턴 존슨",
    sentence:
      "명상하는 은둔자, 목적지 도착을 앞둔 여행자는 책에 빠져들어 시간 밖에 머무른다는 것이 무슨 의미인지 이해할 수 있을 것이다. 과거도 미래도 아닌, 신비주의자들이 말하는 영원히 지속되는 현재에 사는 것의 의미를 말이다.",
    url: "https://www.yes24.com/Product/Goods/103632754",
    tags: ["고독"],
  },
  {
    bookCover: cover9,
    title: "외로움에서 고독으로 가는 여정",
    author: "마인드그라프",
    sentence:
      "고독은 외로움에는 없는 열매가 있다고. 그리고 고독은 혼자만의 시간을 우아하게 보내는 방법이자 사람에게 무언가를 안겨주는 시간이라고.",
    url: "https://product.kyobobook.co.kr/detail/S000061451737",
    tags: ["고독"],
  },
  {
    bookCover: cover8,
    title: "자기만의 방",
    author: "버지니아 울프",
    sentence:
      "자기만의 방을 가진다면, 그리고 우리가 스스로 생각하는 것을 정확하게 표현할 수 있는 용기와 자유의 습성을 가지게 된다면, 우리가 공동의 거실에서 조금 탈출하여 인간을 서로에 대한 관계에서만이 아니라 리얼리티와 관련하여 본다면,",
    url: "https://www.yes24.com/Product/Goods/33631480",
    tags: ["고독"],
  },
  {
    bookCover: cover7,
    title: "ALONE",
    author: "에이미 션 외 21명",
    sentence:
      "현대인들은 예전보다 더 외로워졌으나 예전보다 혼자 있는 것에 덜 익숙하다.",
    url: "https://www.yes24.com/Product/Goods/119287547",
    tags: ["고독"],
  },
  {
    bookCover: cover6,
    title: "내 문장이 그렇게 이상한가요?",
    author: "김정선",
    sentence: "좋은 문장은 주로 빼기를 통해 만들어진다.",
    url: "https://www.yes24.com/Product/Goods/24099626",
    tags: ["퇴고"],
  },
  {
    bookCover: cover5,
    title: "시간을 짓는 공간",
    author: "김승희",
    sentence:
      "땅을 구입한 지 얼마 지나지 않아 설계를 시작할 수밖에 없었다. 토지를 구입한 그날부터 매순간 집을 그리고 있는 나 자신을 발견했기 때문이다.",
    url: "https://www.yes24.com/Product/Goods/34706078",
    tags: ["출판전야"],
  },
  {
    bookCover: cover4,
    title: "어디서 살 것인가",
    author: "유현준",
    sentence:
      "오히려 낭비되는 허술한 공간이 없는 집은 창의성을 질식시킨다. 앞으로 더 많은 사람이 1인 가구가 되어 초소형 원룸에 살게 된다면 대한민국의 창의성은 더 묻혀버릴 것.",
    url: "https://www.yes24.com/Product/Goods/61194204",
    tags: ["출판전야"],
  },
  {
    bookCover: cover3,
    title: "작가의 방",
    author: "알렉스 존슨",
    sentence:
      "우리의 일상 '생활'에서 가능한 멀리 떨어진 호텔 방을 추천합니다. 익명성이 보장되는 단조로운 환경과 친구나 방해물이 없는 낯선 장소는 순식간에 글을 쓸 수 있는 분위기로 빠져들게 도와주죠.",
    url: "https://www.yes24.com/Product/Goods/114247063",
    tags: ["출판전야"],
  },
  {
    bookCover: cover2,
    title: "안녕하세요, 책 읽는 가게입니다",
    author: "아쿠스 다카시",
    sentence:
      "하나의 문화가 뿌리를 내리고 줄기를 뻗고 잎을 무성히 틔우려면 그리고 그 무성한 잎을 생기있게 유지하려면 그 일에 전념할 수 있는 장소의 역할이 무엇보다 중요하다.",
    url: "https://www.yes24.com/Product/Goods/104829014",
    tags: ["출판전야"],
  },
  {
    bookCover: cover1,
    title: "아무튼 서재",
    author: "김윤관",
    sentence:
      "명창정궤(明窓淨几): 일찍이 소동파가 말하길 햇빛이 잘 비치는 창 아래 놓여있는 깨끗한 책상에 붓, 벼루, 종이, 먹이 지극한 명품이니 이 또한 인생의 큰 즐거움이다.",
    url: "https://www.yes24.com/Product/Goods/51095578",
    tags: ["출판전야"],
  },
];

export default BookArray;
